import React, { useState, useEffect } from 'react';
import { Card, Spin, Tag, Input, Button, Select } from 'antd';
import AppTable from '../../components/app_table';
import { useNavigate } from 'react-router-dom';
import { useCurrentStudent } from '../../context/studentContext'; // Import the context
import { useAllAlumni } from '../../context/allAlumniContext';

const { Search } = Input;
const { Option } = Select;

function Alumni() {
  const { allAlumni } = useAllAlumni();
  const { setStudentData } = useCurrentStudent(); // Use the context to get setStudentData
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Place',
      dataIndex: 'place',
      responsive: ['sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: (_, record) => (
        <Tag color={record.drop_our_reason === "Stopped" ? 'red' : 'purple'}>
          {record.drop_our_reason === "Stopped" ? 'Stopped' : 'Hihger Education'}
        </Tag>
      ),
      responsive: ['sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <Button type='link' onClick={() => handleViewClick(record)}>View</Button>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  useEffect(() => {
    setLoading(true);


    // Transform names and places to uppercase and sort alphabetically
    const transformedData = allAlumni
      .map(student => ({
        ...student,
        name: student.name.toUpperCase(),
        place: student.place ? student.place.toString().toUpperCase() : '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    setTableData(transformedData);
    setFilteredData(transformedData);
    setLoading(false);
  }, [allAlumni]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    applyFilters(query);
  };

  const applyFilters = (query) => {
    const filteredData = tableData.filter(student => {
      const name = student.name ? student.name.toLowerCase() : '';
      const place = student.place ? student.place.toLowerCase() : '';
      return name.includes(query) || place.includes(query);
    });

    setFilteredData(filteredData);
  };

  const handleViewClick = (record) => {
    setStudentData(record); // Set the current student data
    navigate(`/student_details`); // Navigate to the student details page
  };

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <Card
        title='Alumni'
        style={{ width: '100%', margin: '0 auto' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Search
            placeholder="Search alumni"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: '100%', maxWidth: '300px' }}
          />
        </div>

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <Spin size="large" />
          </div>
        ) : (
          <AppTable columns={columns} data={filteredData} style={{ marginTop: 10 }} />
        )}
      </Card>
    </div>
  );
}

export default Alumni;