// services/firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';


//firebase production server
const firebaseConfig = {
  apiKey: "AIzaSyCo5G1e9DuIvo6hkfM_7vfJ6qW0kTXMco8",
  authDomain: "madin-erp.firebaseapp.com",
  projectId: "madin-erp",
  storageBucket: "madin-erp.appspot.com",
  messagingSenderId: "472233338589",
  appId: "1:472233338589:web:bbb7d800979021aac5dd34",
  measurementId: "G-NQD42HZ4H4"
};

//firebase development server
// const firebaseConfig = {
//   apiKey: "AIzaSyAVthwG2zG64pXJQ0rju9gSqIyDOurSKHk",
//   authDomain: "erp-devdb.firebaseapp.com",
//   projectId: "erp-devdb",
//   storageBucket: "erp-devdb.appspot.com",
//   messagingSenderId: "761321305750",
//   appId: "1:761321305750:web:ae5fab01f7331f29dff4b7",
//   measurementId: "G-HB3PEYDER1"
// };

const app = firebase.initializeApp(firebaseConfig);

const firestore = app.firestore()

const firebaseAuth = app.auth();

/////////////
const addDataToFirestore = async (collectionName, data) => {
  try {
    // Get a reference to the document using the specified ID
    const docRef = firestore.collection(collectionName).doc();

    // Add data to the document with timestamps
    await docRef.set({
      ...data,
      id: docRef.id, // Add the ID to the data
      created_at: firebase.firestore.FieldValue.serverTimestamp(), // Add created_at timestamp
      updated_at: firebase.firestore.FieldValue.serverTimestamp()  // Add updated_at timestamp
    });

    // Return the document reference
    return docRef;
  } catch (error) {
    console.error("Error adding document to Firestore: ", error);
    throw error;
  }
};
/////////////

const addDataToSubcollection = async (collectionName, docId, subcollectionName, data) => {
  try {
    const subcollectionRef = firestore.collection(collectionName).doc(docId).collection(subcollectionName).doc();
    await subcollectionRef.set({
      ...data,
      id: subcollectionRef.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp()
    });
    return { id: subcollectionRef.id, ...data };
  } catch (error) {
    console.error("Error adding document to subcollection: ", error);
    throw error;
  }
};

export { app, firestore, addDataToFirestore, addDataToSubcollection, firebaseAuth, firebase };
