import React, { useState, useEffect, useContext } from 'react';
import { firestore } from '../../services/firebase';
import { Button, Form, Input, Select, message } from 'antd';
import { addDataToFirestore } from '../../services/firebase';
import collection from '../../constants/collection';
import axios from 'axios';
import urls from '../../constants/urls';
import { AuthContext } from '../../context/AuthContext';
import userTypes from '../../constants/userTypes';
import { useUserData } from '../../context/userDataContext';

const AddStaffForm = () => {
    const { user } = useContext(AuthContext);

    const [form] = Form.useForm();
    const [institutionList, setInstitutionList] = useState([]);
    const [shouldDisplayInstitutionSelect, setShouldDisplayInstitutionSelect] = useState(false);
    const [token, setToken] = useState();
    const { userData } = useUserData();

    useEffect(() => {
        getToken(); // Get Auth Token

        const fetchData = async () => {
            try {
                const querySnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).get();
                const fetchedData = querySnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setInstitutionList(fetchedData);

                // Set default values based on user role
                if (userData.role === userTypes.INSTITUTION_HEAD) {
                    form.setFieldsValue({
                        institution: userData.institution,
                    });
                    setShouldDisplayInstitutionSelect(true);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [form, userData.institution, userData.role]);

    const getToken = () => {
        user.getIdToken().then((token) => {
            setToken(token);
        }).catch((error) => {
            console.error('Error getting token:', error);
        });
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            // Ensure the institution value is set for roles that require it
            if (!values.institution && (values.role === 'institution_head' || values.role === 'staff')) {
                values.institution = userData.institution;
            }
            const response = await axios.post(
                urls.BASE_URL + urls.ADD_USER,
                {
                    "name": values.name,
                    "email": values.email,
                    "password": values.password,
                    "role": values.role,
                    "institution": values.institution
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    }
                });
            message.success(response.data.message);

            form.resetFields(['name', 'email', 'password', 'role']);
            // } catch (errorInfo) {
            //     message.error(errorInfo.response.data);
            //     console.error('Failed to submit:', errorInfo.response.data);
            // }

        } catch (errorInfo) {
            // Handle validation errors differently from API errors
            if (errorInfo.errorFields) {
                message.error('Please fill out all required fields.');
                // console.error('Validation failed:', errorInfo.errorFields);
            } else if (errorInfo.response && errorInfo.response.data) {
                message.error(errorInfo.response.data.message || 'Failed to submit');
                // console.error('API request failed:', errorInfo.response.data);
            } else {
                message.error('Submission failed. Please try again.');
                // console.error('Unknown error during submit:', errorInfo);
            }
        }
    };

    const handleRoleChange = (value) => {
        setShouldDisplayInstitutionSelect(value === 'institution_head' || value === 'staff');
    };

    return (
        <Form
            layout="vertical"
            form={form}
            style={{
                maxWidth: 600,
            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter the name' }]}
            >
                <Input placeholder="Name of Staff" />
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        // message: 'Please enter an email'
                    },
                    {
                        type: 'email',
                        message: '',
                    },
                    {
                        validator: (_, value) =>
                            value && value.endsWith('@madin.edu.in')
                                ? Promise.resolve()
                                : Promise.reject('Please enter a valid madin.edu.in email'),
                    },
                ]}
            >
                <Input placeholder="Email of Staff" />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    { required: true, message: 'Please enter a password' },
                    { min: 8, message: 'Password must be at least 8 characters' }, // Added validation rule
                ]}
            >
                <Input.Password placeholder="Password of Staff" />
            </Form.Item>

            <Form.Item
                label="Role"
                name="role"
                rules={[
                    {
                        required: true,
                        message: 'Please select role',
                    },
                ]}
            >
                <Select onChange={handleRoleChange}>
                    {userData.role === userTypes.ADMIN && (
                        <>
                            <Select.Option value="admin">Admin</Select.Option>
                            <Select.Option value="institution_head">Institution Head</Select.Option>
                        </>
                    )}
                    <Select.Option value="staff">Staff</Select.Option>
                </Select>
            </Form.Item>

            {shouldDisplayInstitutionSelect && (
                <Form.Item
                    label="Institution"
                    name="institution"
                    rules={[
                        {
                            required: true,
                            message: 'Please select institution',
                        },
                    ]}
                >
                    <Select disabled={userData.role === userTypes.INSTITUTION_HEAD} defaultValue={userData.role === userTypes.INSTITUTION_HEAD ? userData.institution : undefined}>
                        {institutionList.map(institution => (
                            <Select.Option key={institution.key} value={institution.key}>
                                {institution.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddStaffForm;