import React, { useState, useEffect } from 'react';
import { Card, Button, Drawer, List, Tooltip, Popconfirm, Alert, Tabs, Row, Col, Statistic, DatePicker } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, EyeTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteAttendanceRecord } from './store';
import AddAttendanceForm from './AddAttendanceForm';
import EditAttendanceForm from './EditAttendanceForm';
import { useInstitution } from '../../context/InstitutionContext';
import { useUserData } from '../../context/userDataContext';
import { firestore } from '../../services/firebase';
import { useAllStudents } from '../../context/allStudentContext';
import collection from '../../constants/collection';
import moment from 'moment';

const { TabPane } = Tabs;

function AttendanceList() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [activeBatch, setActiveBatch] = useState(null);
  const [attendanceCounts, setAttendanceCounts] = useState({});
  const [batchesTakenAttendanceToday, setBatchesTakenAttendanceToday] = useState(0);
  const [presenteesCount, setPresenteesCount] = useState(0);
  const [absenteesCount, setAbsenteesCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment()); // Add state for selected date
  const { allStudents } = useAllStudents();
  const { userData } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const institutionId = userData?.role === 'institution_head' ? userData.institution : location?.state?.instId;

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);
  const openEditDrawer = (record) => {
    setSelectedRecord(record);
    setEditDrawerOpen(true);
  };
  const closeEditDrawer = () => setEditDrawerOpen(false);

  const handleDelete = async (key) => {
    try {
      await deleteAttendanceRecord(key);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleView = (record) => {
    navigate('/attendance_details', { state: { record } });
  };

  useEffect(() => {
    if (!institutionId) return; // Ensure institutionId is defined

    const unsubscribeBatches = firestore.collection(collection.BATCHES_COLLECTION)
      .where('institution', '==', institutionId)
      .onSnapshot(querySnapshot => {
        const batchList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        batchList.sort((a, b) => a.name.localeCompare(b.name));
        setBatches(batchList);
        if (batchList.length > 0) {
          setActiveBatch(batchList[0].id);
        }
      });

    return () => unsubscribeBatches();
  }, [institutionId]);

  useEffect(() => {
    if (!institutionId) return; // Ensure institutionId is defined

    const unsubscribeAttendance = firestore.collection(collection.ATTENDANCE_LIST_COLLECTION)
      .where('institution', '==', institutionId)
      .where('date', '==', selectedDate.format('YYYY-MM-DD'))
      .onSnapshot(querySnapshot => {
        const attendanceList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAttendanceData(attendanceList);

        const todayAttendanceList = attendanceList.filter(attendance => attendance.attended);

        const batchAttendanceCounts = {};
        const attendedBatchIds = new Set();
        const batchesTodaySet = new Set();

        todayAttendanceList.forEach(attendance => {
          const batchId = attendance.batch;
          attendedBatchIds.add(batchId);
          if (!batchAttendanceCounts[batchId]) {
            batchAttendanceCounts[batchId] = 0;
          }
          batchAttendanceCounts[batchId]++;
          batchesTodaySet.add(batchId);
        });

        const studentCounts = {};
        allStudents.forEach(student => {
          if (student.institution === institutionId) {
            const batchId = student.batch;
            if (!studentCounts[batchId]) {
              studentCounts[batchId] = 0;
            }
            studentCounts[batchId]++;
          }
        });

        const attendedStudentCounts = {};
        allStudents.forEach(student => {
          if (attendedBatchIds.has(student.batch)) {
            const batchId = student.batch;
            if (!attendedStudentCounts[batchId]) {
              attendedStudentCounts[batchId] = 0;
            }
            attendedStudentCounts[batchId]++;
          }
        });

        setAttendanceCounts(attendedStudentCounts);
        setBatchesTakenAttendanceToday(batchesTodaySet.size);

        // Calculate presentees and absentees
        calculatePresenteesAndAbsentees(todayAttendanceList, studentCounts);
      });

    return () => unsubscribeAttendance();
  }, [institutionId, allStudents, selectedDate]);

  const calculatePresenteesAndAbsentees = async (attendanceList, studentCounts) => {
    const latestAttendance = {};
    attendanceList.forEach(attendance => {
      const batchId = attendance.batch;
      if (!latestAttendance[batchId] || moment(attendance.time, 'hh:mm A').isAfter(moment(latestAttendance[batchId].time, 'hh:mm A'))) {
        latestAttendance[batchId] = attendance;
      }
    });

    let totalAbsentees = 0;
    const batchesWithAttendanceToday = new Set(Object.keys(latestAttendance));

    for (const batchId in latestAttendance) {
      const attendance = latestAttendance[batchId];
      const absenteesSnapshot = await firestore.collection(collection.ABSENTEES_COLLECTION)
        .where('attendanceId', '==', attendance.id)
        .get();
      totalAbsentees += absenteesSnapshot.size;
    }

    const totalStudents = Object.entries(studentCounts)
      .filter(([batchId]) => batchesWithAttendanceToday.has(batchId))
      .reduce((sum, [, count]) => sum + count, 0);

    const totalPresentees = totalStudents - totalAbsentees;

    setPresenteesCount(totalPresentees);
    setAbsenteesCount(totalAbsentees);
  };

  const groupedData = attendanceData.reduce((acc, record) => {
    const batch = record.batch;
    if (!acc[batch]) {
      acc[batch] = [];
    }
    acc[batch].push(record);
    return acc;
  }, {});

  const totalAttendanceCount = Object.values(attendanceCounts).reduce((sum, count) => sum + count, 0);
  console.log(selectedDate);

  useEffect(() => {
    if (!institutionId) return; // Ensure institutionId is defined

    const unsubscribeStaff = firestore.collection(collection.STAFF_COLLECTION)
      .where('institution', '==', institutionId)
      .onSnapshot(querySnapshot => {
        const staffList = {};
        querySnapshot.docs.forEach(doc => {
          const data = doc.data();
          staffList[doc.id] = data.name; // Assuming each staff document has a 'name' field
        });
        setStaffData(staffList);
      });

    return () => unsubscribeStaff();
  }, [institutionId]);

  const [staffData, setStaffData] = useState({}); // Add state for staff data

  return (
    <div>
      <Card
        title="Attendance List"
        extra={
          <Row gutter={16} align="middle">
            <Col>
              <DatePicker
                // value={selectedDate} 
                onChange={date => setSelectedDate(date)}
                style={{ marginRight: 16 }}
                format="DD-MM-YYYY"
              />
            </Col>
            <Col>
              <Button type="primary" onClick={openDrawer}>
                <PlusOutlined /> Add Attendance
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={16} style={{ marginBottom: 16 }} xs={24} sm={6} md={6} lg={6}>
          <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title={`Total Students`}
                value={allStudents.filter(student => student.institution === institutionId).length}
                valueStyle={{ color: '#9307f0' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title={`Batches Taken Attendance`}
                value={`${batchesTakenAttendanceToday} / ${batches.length}`}
                valueStyle={{ color: '#0741f0' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title={`Presentees`}
                value={presenteesCount}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title={`Absentees`}
                value={absenteesCount}
                valueStyle={{ color: '#cf1322' }}
              />
            </Card>
          </Col>
        </Row>
        <Tabs activeKey={activeBatch} onChange={setActiveBatch}>
          {batches.map(batch => (
            <TabPane tab={batch.name} key={batch.id}>
              <List
                itemLayout="horizontal"
                dataSource={groupedData[batch.id] || []}
                renderItem={record => {
                  const staffNames = record.staffIds.map(id => staffData[id]).filter(Boolean).join(', ');
                  return (
                    <List.Item
                      actions={[
                        <Tooltip title="View">
                          <EyeTwoTone onClick={() => handleView(record)} style={{ color: 'green', cursor: 'pointer' }} />
                        </Tooltip>,
                        <Tooltip title="Edit">
                          <EditTwoTone onClick={() => openEditDrawer(record)} style={{ color: 'blue', cursor: 'pointer', marginLeft: 12 }} />
                        </Tooltip>,
                        <Popconfirm
                          title="Are you sure to delete this record?"
                          onConfirm={() => handleDelete(record.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete">
                            <DeleteTwoTone style={{ color: 'red', cursor: 'pointer', marginLeft: 12 }} />
                          </Tooltip>
                        </Popconfirm>
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          <span>
                            {record.name} {record.attended && <CheckCircleTwoTone twoToneColor="#52c41a" />} {/* Conditionally render green check mark */}
                          </span>
                        }
                        description={
                          <>
                            {`${moment(record.date + ' ' + record.time, 'YYYY-MM-DD hh:mm A').format('DD-MM-YYYY hh:mm A')}`}
                            {/* {`${moment(record.date, 'YYYY-MM-DD').format('DD-MM-YYYY')} ${moment(record.time, 'HH:mm:ss').format('hh:mm A')}`} */}
                            <br />
                            {`Led by: ${staffNames}`}
                          </>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </TabPane>
          ))}
        </Tabs>
      </Card>
      <Drawer title="Add Attendance" width={500} onClose={closeDrawer} open={isDrawerOpen}>
        <AddAttendanceForm onClose={closeDrawer} activeBatch={activeBatch} />
      </Drawer>
      <Drawer title="Edit Attendance" width={500} onClose={closeEditDrawer} open={isEditDrawerOpen}>
        <EditAttendanceForm record={selectedRecord} onClose={closeEditDrawer} />
      </Drawer>
    </div>
  );
}

export default AttendanceList;