import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Row, Col, Statistic, List, Skeleton } from 'antd';
import { EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useInstitution } from '../../context/InstitutionContext';
import { useUserData } from '../../context/userDataContext';
import { useAllStudents } from '../../context/allStudentContext';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';
import moment from 'moment';

function InstitutionForAttendance() {
  const [tableData, setTableData] = useState([]);
  const [institutionStats, setInstitutionStats] = useState({});
  const [overallStats, setOverallStats] = useState({
    totalStudents: 0,
    totalBatches: 0,
    batchesTakenAttendanceToday: 0,
    totalPresentees: 0,
    totalAbsentees: 0,
    presenteePercentage: 0,
    absenteePercentage: 0,
    attendanceBatchPercentage: 0,
    studentsWithoutAttendance: 0,
    studentsWithoutAttendancePercentage: 0,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { institutionList } = useInstitution();
  const { userData } = useUserData();
  const { allStudents } = useAllStudents();

  const handleViewClick = (record) => {
    const institutionId = userData.role === 'institution_head' ? userData.institution : record.id;
    navigate(`/attendance`, { state: { instId: institutionId } });
  };

  useEffect(() => {
    setTableData(institutionList);
  }, [institutionList]);

  useEffect(() => {
    const fetchInstitutionStats = async () => {
      setLoading(true);
      const today = moment().format('YYYY-MM-DD');
      const attendanceSnapshot = await firestore.collection(collection.ATTENDANCE_LIST_COLLECTION).where('date', '==', today).get();
      const attendanceList = attendanceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const batchesSnapshot = await firestore.collection(collection.BATCHES_COLLECTION).get();
      const batchesList = batchesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const stats = {};
      let overallTotalStudents = 0;
      let overallTotalBatches = 0;
      let overallBatchesTakenAttendanceToday = 0;
      let overallTotalPresentees = 0;
      let overallTotalAbsentees = 0;

      for (const institution of institutionList) {
        const institutionId = institution.id;

        // Total students
        const institutionStudents = allStudents.filter(student => student.institution === institutionId);
        const totalStudents = institutionStudents.length;
        overallTotalStudents += totalStudents;

        // Batches
        const institutionBatches = batchesList.filter(batch => batch.institution === institutionId);
        const totalBatches = institutionBatches.length;
        overallTotalBatches += totalBatches;

        // Today's attendance
        const todayAttendanceList = attendanceList.filter(attendance => attendance.institution === institutionId);

        // Calculate presentees and absentees
        const latestAttendance = {};
        todayAttendanceList.filter(attendance => attendance.attended).forEach(attendance => {
          const batchId = attendance.batch;
          if (!latestAttendance[batchId] || moment(attendance.time, 'hh:mm A').isAfter(moment(latestAttendance[batchId].time, 'hh:mm A'))) {
            latestAttendance[batchId] = attendance;
          }
        });

        const batchesWithAttendanceToday = new Set(Object.keys(latestAttendance));
        let totalPresentees = 0;
        let totalAbsentees = 0;

        for (const batchId in latestAttendance) {
          const attendance = latestAttendance[batchId];
          const absenteesSnapshot = await firestore.collection(collection.ABSENTEES_COLLECTION)
            .where('attendanceId', '==', attendance.id)
            .get();
          totalAbsentees += absenteesSnapshot.size;
        }

        const totalStudentsInBatches = institutionStudents.filter(student => batchesWithAttendanceToday.has(student.batch)).length;
        totalPresentees = totalStudentsInBatches - totalAbsentees;

        overallTotalPresentees += totalPresentees;
        overallTotalAbsentees += totalAbsentees;
        overallBatchesTakenAttendanceToday += batchesWithAttendanceToday.size;

        stats[institutionId] = {
          totalStudents,
          totalBatches,
          batchesTakenAttendanceToday: batchesWithAttendanceToday.size,
          totalPresentees,
          totalAbsentees,
        };
      }

      setInstitutionStats(stats);
      setOverallStats({
        totalStudents: overallTotalStudents,
        totalBatches: overallTotalBatches,
        batchesTakenAttendanceToday: overallBatchesTakenAttendanceToday,
        totalPresentees: overallTotalPresentees,
        totalAbsentees: overallTotalAbsentees,
        presenteePercentage: overallTotalStudents ? ((overallTotalPresentees / overallTotalStudents) * 100).toFixed(2) : 0,
        absenteePercentage: overallTotalStudents ? ((overallTotalAbsentees / overallTotalStudents) * 100).toFixed(2) : 0,
        attendanceBatchPercentage: overallTotalBatches ? ((overallBatchesTakenAttendanceToday / overallTotalBatches) * 100).toFixed(2) : 0,
        studentsWithoutAttendance: overallTotalStudents - (overallTotalPresentees + overallTotalAbsentees),
        studentsWithoutAttendancePercentage: overallTotalStudents ? (((overallTotalStudents - (overallTotalPresentees + overallTotalAbsentees)) / overallTotalStudents) * 100).toFixed(2) : 0,
      });
      setLoading(false);
    };

    fetchInstitutionStats();
  }, [institutionList, allStudents]);

  return (
    <div>
      <Card title="Overall Statistics">
        {loading ? (
          <Skeleton active />
        ) : (
          <Row gutter={16} style={{ marginBottom: 16 }} xs={24} sm={6} md={6} lg={6}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
              <Card bordered={false}>
                <Statistic
                  title={`Total Students`}
                  value={overallStats.totalStudents}
                  valueStyle={{ color: '#9307f0' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
              <Card bordered={false}>
                <Statistic
                  title={`Batches Taken Attendance Today`}
                  value={`${overallStats.batchesTakenAttendanceToday} / ${overallStats.totalBatches} (${overallStats.attendanceBatchPercentage}%)`}
                  valueStyle={{ color: '#0741f0' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
              <Card bordered={false}>
                <Statistic
                  title={`Current Presentees`}
                  value={`${overallStats.totalPresentees} (${overallStats.presenteePercentage}%)`}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
              <Card bordered={false}>
                <Statistic
                  title={`Current Absentees`}
                  value={`${overallStats.totalAbsentees} (${overallStats.absenteePercentage}%)`}
                  valueStyle={{ color: '#cf1322' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
              <Card bordered={false}>
                <Statistic
                  title={`Students Without Attendance`}
                  value={`${overallStats.studentsWithoutAttendance} (${overallStats.studentsWithoutAttendancePercentage}%)`}
                  valueStyle={{ color: '#ff8c00' }}
                />
              </Card>
            </Col>
          </Row>
        )}
      </Card>
      <Card title="Institutions">
        {loading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={tableData}
            renderItem={record => {
              const stats = institutionStats[record.id] || {};
              return (
                <List.Item
                  style={{ padding: '16px' }}
                  actions={[
                    <Tooltip title="View">
                      <EyeTwoTone onClick={() => handleViewClick(record)} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Row gutter={16} align="middle">
                        <Col flex="1">
                          <span>{record.name}</span>
                        </Col>
                        <Col flex="0.4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span style={{ color: '#9307f0' }}>{stats.totalStudents || 0}</span> 
                          <span style={{ color: '#0741f0' }}>{stats.batchesTakenAttendanceToday || 0} / {stats.totalBatches || 0}</span>
                          <span style={{ color: '#3f8600' }}>{stats.totalPresentees || 0}</span>
                          <span style={{ color: '#cf1322' }}>{stats.totalAbsentees || 0}</span>
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              );
            }}
          />
        )}
      </Card>
    </div>
  );
}

export default InstitutionForAttendance;