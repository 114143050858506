import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, TimePicker, Select, Checkbox } from 'antd';
import { addAttendanceRecord, fetchStaffs } from './store';
import { useLocation } from 'react-router-dom';
import { useUserData } from '../../context/userDataContext';
import moment from 'moment';
import { firestore } from '../../services/firebase';

const AddAttendanceForm = ({ onClose, activeBatch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [segments, setSegments] = useState([]);
  const [isSegmented, setIsSegmented] = useState(false);
  const [hasSegments, setHasSegments] = useState(false);
  const { userData } = useUserData();
  const location = useLocation();
  const institutionId = userData.role === 'institution_head' ? userData.institution : location.state.instId;

  useEffect(() => {
    const fetchData = async () => {
      const staffs = await fetchStaffs({ filter: { institution: institutionId } });
      setStaffList(staffs || []);
    };
    fetchData();
  }, [institutionId]);

  useEffect(() => {
    if (activeBatch) {
      const checkSegments = async () => {
        const snapshot = await firestore.collection('batches').doc(activeBatch).collection('segments').get();
        const segmentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSegments(segmentsData);
        setHasSegments(segmentsData.length > 0);
      };
      checkSegments();
    }
  }, [activeBatch]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { name, dates, time, staffIds = [], segmentStaffs } = values;
      const formattedTime = time.format('hh:mm A');
      const combinedStaffIds = isSegmented
        ? [...staffIds, ...Object.values(segmentStaffs)]
        : staffIds;

      const batchPromises = dates.map(date => {
        const formattedDate = date.format('YYYY-MM-DD');
        const attendanceRecord = {
          name,
          date: formattedDate,
          time: formattedTime,
          institution: institutionId,
          batch: activeBatch,
          staffIds: combinedStaffIds,
        };

        // Add segmentStaffs only if it is defined
        if (segmentStaffs) {
          attendanceRecord.segmentStaffs = segmentStaffs;
        }

        return addAttendanceRecord(attendanceRecord);
      });
      await Promise.all(batchPromises);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Error adding document:', error);
    } finally {
      setLoading(false);
    }
  };

 

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Subject Name"
        rules={[{ required: true, message: 'Please input the name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="dates"
        label="Dates"
        rules={[{ required: true, message: 'Please select the dates!' }]}
      >
        <DatePicker 
          multiple 
          disabledDate={(current) => {
            const startOfMonth = moment().startOf('month');
            const endOfMonth = moment().endOf('month');
            return current && (current < startOfMonth || current > endOfMonth);
          }}
        />
      </Form.Item>
      <Form.Item
        name="time"
        label="Time"
        rules={[{ required: true, message: 'Please select the time!' }]}
      >
        <TimePicker format="hh:mm A" use12Hours />
      </Form.Item>
      {hasSegments && (
        <Form.Item>
          <Checkbox checked={isSegmented} onChange={(e) => setIsSegmented(e.target.checked)}>
            Segmented
          </Checkbox>
        </Form.Item>
      )}
      {isSegmented ? (
        segments.map(segment => (
          <Form.Item
            key={segment.id}
            name={['segmentStaffs', segment.id]}
            label={`Staff for ${segment.name}`}
            rules={[{ required: true, message: `Please select the staff for ${segment.name}!` }]}
          >
            <Select
              placeholder="Select staff"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {staffList.map(staff => (
                <Select.Option key={staff.id} value={staff.userId}>
                  {staff.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ))
      ) : (
        <Form.Item
          name="staffIds"
          label="Attendance Staff"
          rules={[{ required: true, message: 'Please select the staff!' }]}
        >
          <Select
            mode="multiple"
            placeholder="Select staff"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {staffList.map(staff => (
              <Select.Option key={staff.id} value={staff.userId}>
                {staff.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add
        </Button>
      </Form.Item>
      
     
    </Form>
  );
};

export default AddAttendanceForm;
