import React, { useState, useEffect } from 'react';
import { Card, Spin, Drawer, Tag, Input, Button, Select } from 'antd';
import AppButton from '../../components/app_button';
import AppTable from '../../components/app_table';
import '../../assets/styles.css';
import AddStudentForm from './AddStudentForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCurrentStudent } from '../../context/studentContext';
import { useUserData } from '../../context/userDataContext';
import { useAllStudents } from '../../context/allStudentContext';
import userTypes from '../../constants/userTypes';

const { Search } = Input;
const { Option } = Select; // Destructure Option from Select

function Students() {
  const { allStudents } = useAllStudents();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { setStudentData } = useCurrentStudent();
  const { userData } = useUserData();
  const location = useLocation();
  const { batch } = location.state || {};

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleViewClick = (record) => {
    setStudentData(record);
    navigate(`/student_details`);
  };

  // Add responsive behavior to the table columns based on device size
  const columns = [
   
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.imagePath ? (
            <img src={record.imagePath} alt={name} style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }} />
          ) : (
            <div style={{ 
              width: 40, 
              height: 40, 
              borderRadius: '50%', 
              backgroundColor: '#ccc', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              marginRight: 10 
            }}>
              <span style={{ color: '#fff', fontWeight: 'bold' }}>{name.charAt(0).toUpperCase()}</span>
            </div>
          )}
          <span>{name}</span>
        </div>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Place',
      dataIndex: 'place',
      responsive: ['sm', 'md','lg', 'xl'],
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (_, record) => (
    //     <>
    //       <span>
    //         {record.adhaar && record.status ? (
    //           <Tag color='green'>verified</Tag>
    //         ) : (
    //           <Tag color='red'>not verified</Tag>
    //         )}
    //       </span>
    //       <span>
    //         {record.father ? (
    //           <Tag color='green'>family updated</Tag>
    //         ) : (
    //           <Tag color='red'>family not updated</Tag>
    //         )}
    //       </span>
    //     </>
    //   ),
    //   responsive: ['sm', 'md','lg', 'xl'],
    // },
    {
      title: 'Student ID',
      dataIndex: 'StudentID',
      responsive: ['xs','sm', 'md','lg', 'xl'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <span>
          <Button type='link' onClick={() => handleViewClick(record)}>View</Button>
        </span>
      ),
      responsive: ['xs','sm', 'md','lg', 'xl'],
    },
  ];

  const [verificationFilter, setVerificationFilter] = useState('all'); // New state for filter

  useEffect(() => {
    setLoading(true);

    let filteredData;
    if (batch) {
      filteredData = allStudents.filter(student => student.batch === batch.id);
    } else {
      if (userData.role === userTypes.ADMIN || userData.role ===userTypes.MANAGER) {
        filteredData = allStudents;
      }
      if (userData.role === userTypes.INSTITUTION_HEAD) {
        filteredData = allStudents.filter(student => student.institution === userData.institution);
      }
    }

    // Filter students with status undefined or 1
    filteredData = filteredData.filter(student => student.status === undefined || student.status === 1);

    // Transform names and places to uppercase and sort alphabetically
    filteredData = filteredData
      .map(student => ({
        ...student,
        name: student.name.toUpperCase(),
        place: student.place ? student.place.toString().toUpperCase() : '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    setTableData(filteredData);
    setFilteredData(filteredData); // Set initial filtered data
    setLoading(false);
  }, [allStudents, batch, userData.role, userData.institution]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    applyFilters(query, verificationFilter);
  };

  const handleVerificationFilterChange = (value) => {
    setVerificationFilter(value);
    applyFilters(searchQuery, value);
  };

  const applyFilters = (query, verification) => {
    const filteredData = tableData.filter(student => {
      const name = student.name ? student.name.toLowerCase() : '';
      const place = student.place ? student.place.toLowerCase() : '';
      const adhaar = student.adhaar ? student.adhaar.toString().toLowerCase() : '';
      const studentID = student.StudentID ? student.StudentID.toLowerCase() : ''; // Convert StudentID to lowercase
      const isVerified = student.adhaar && student.status;

      const matchesQuery = 
        name.includes(query) || 
        place.includes(query) || 
        adhaar.includes(query) || 
        studentID.includes(query); // Include StudentID in search

      const matchesVerification = 
        verification === 'all' || 
        (verification === 'verified' && isVerified) || 
        (verification === 'not_verified' && !isVerified);

      return matchesQuery && matchesVerification;
    });

    setFilteredData(filteredData);
  };

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <Card
        title={batch ? batch.name : 'Students'}
        extra={
          userData.role === 'institution_head' ? (
            <AppButton text='Add Student' onClick={openDrawer} />
          ) : null
        }
        style={{ width: '100%', margin: '0 auto' }}
      >
        {userData.role === 'institution_head' && (
          <Drawer title='Add Student' width={500} onClose={closeDrawer} open={isDrawerOpen}>
            <AddStudentForm />
          </Drawer>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Search
            placeholder="Search students"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: '100%', maxWidth: '300px' }}
          />
          <Select
            value={verificationFilter}
            onChange={handleVerificationFilterChange}
            style={{ width: 150, marginLeft: 10 }}
          >
            <Option value="all">All</Option>
            <Option value="verified">Verified</Option>
            <Option value="not_verified">Not Verified</Option>
          </Select>
        </div>

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <Spin size="large" />
          </div>
        ) : (
          <AppTable columns={columns} data={filteredData} style={{marginTop:10}}/>
        )}
      </Card>
    </div>
  );
}

export default Students;