import React, { createContext, useContext, useState, useEffect } from 'react';

const AllAlumniContext = createContext();

export const AllAlumniProvider = ({ children }) => {
  const [allAlumni, setAllAlumni] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedAlumni = localStorage.getItem('allAlumni');
    return savedAlumni ? JSON.parse(savedAlumni) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allAlumni changes
    localStorage.setItem('allAlumni', JSON.stringify(allAlumni));
  }, [allAlumni]);

  return (
    <AllAlumniContext.Provider value={{ allAlumni, setAllAlumni }}>
      {children}
    </AllAlumniContext.Provider>
  );
};

export const useAllAlumni = () => {
  return useContext(AllAlumniContext);
};