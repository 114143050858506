import React, { useEffect, useState } from 'react';
import { Form, Input, Button, List, Radio, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'; // Import the DeleteOutlined icon
import { useAllStudents } from '../../context/allStudentContext';
import { firestore, addDataToSubcollection, firebase } from '../../services/firebase'; // Import firebase

function SplitBatchScreen({ record, onClose }) {
  const [form] = Form.useForm();
  const { allStudents } = useAllStudents();
  const [batchStudents, setBatchStudents] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);

  useEffect(() => {
    if (record) {
      const studentsInBatch = allStudents.filter(student => student.batch === record.key);
      setBatchStudents(studentsInBatch);

      const unsubscribe = firestore
        .collection('batches')
        .doc(record.key)
        .collection('segments')
        .onSnapshot(snapshot => {
          const segmentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSegments(segmentsData);
        });

      return () => unsubscribe();
    }
  }, [record, allStudents]);

  const onFinish = async (values) => {
    try {
      await addDataToSubcollection('batches', record.key, 'segments', {
        name: values.segmentName,
        members: []
      });
      form.resetFields();
    } catch (error) {
      console.error("Error adding segment: ", error);
    }
    onClose();
  };

  const addStudentToSegment = async (segmentId, studentId) => {
    try {
      const segmentRef = firestore.collection('batches').doc(record.key).collection('segments').doc(segmentId);
      await segmentRef.update({
        members: firebase.firestore.FieldValue.arrayUnion(studentId)
      });
    } catch (error) {
      console.error("Error adding student to segment: ", error);
    }
  };

  const removeStudentFromSegment = async (segmentId, studentId) => {
    try {
      const segmentRef = firestore.collection('batches').doc(record.key).collection('segments').doc(segmentId);
      await segmentRef.update({
        members: firebase.firestore.FieldValue.arrayRemove(studentId)
      });
    } catch (error) {
      console.error("Error removing student from segment: ", error);
    }
  };

  const deleteSegment = async (segmentId) => {
    try {
      await firestore.collection('batches').doc(record.key).collection('segments').doc(segmentId).delete();
    } catch (error) {
      console.error("Error deleting segment: ", error);
    }
  };

  const studentsInSegments = segments.reduce((acc, segment) => {
    return acc.concat(segment.members);
  }, []);

  const availableStudents = batchStudents.filter(student => !studentsInSegments.includes(student.id));

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Segment Name"
          name="segmentName"
          rules={[{ required: true, message: 'Please input the batch name!' }]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Segment
          </Button>
        </Form.Item>
      </Form>
      <Radio.Group onChange={(e) => setSelectedSegment(e.target.value)} style={{ width: '100%' }}>
        {segments.map(segment => (
          <div key={segment.id} style={{ width: '100%' }}>
            <Radio value={segment.id}>
              {segment.name}
            </Radio>
            <Popconfirm
              title="Are you sure to delete this segment?"
              onConfirm={() => deleteSegment(segment.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<DeleteOutlined />} style={{ color: 'red', marginLeft: '10px' }} />
            </Popconfirm>
            <List
              bordered
              dataSource={batchStudents.filter(student => segment.members.includes(student.id))}
              renderItem={student => (
                <List.Item
                  actions={[
                    <Button type="link" onClick={() => removeStudentFromSegment(segment.id, student.id)}>Remove</Button>
                  ]}
                >
                  {student.name}
                </List.Item>
              )}
            />
          </div>
        ))}
      </Radio.Group>
      <h3>Students Not Included in Any Segments</h3> {/* Title for the available students list */}
      <List
        bordered
        dataSource={availableStudents}
        renderItem={student => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => selectedSegment && addStudentToSegment(selectedSegment, student.id)}>Add</Button>
            ]}
          >
            {student.name}
          </List.Item>
        )}
      />
    </div>
  );
}

export default SplitBatchScreen;
