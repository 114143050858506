import React, { useState, useEffect } from 'react';
import { Form, Input, Button, TimePicker, Select } from 'antd';
import moment from 'moment';
import { updateAttendanceRecord, fetchStaffs } from './store';
import { useUserData } from '../../context/userDataContext';

const EditAttendanceForm = ({ record, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const { userData } = useUserData();
  const institutionId = userData.role === 'institution_head' ? userData.institution : record.institution;

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        time: moment(record.time, 'hh:mm A'),
        staffIds: record.staffIds,
      });
    }
  }, [record, form]);

  useEffect(() => {
    const fetchData = async () => {
      const staffs = await fetchStaffs({ filter: { institution: institutionId } });
      setStaffList(staffs || []); 
    };
    fetchData();
  }, [institutionId]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      console.log(record);
      await updateAttendanceRecord(record.id, {
        name: values.name,
        time: values.time.format('hh:mm A'),
        institution: institutionId,
        staffIds: values.staffIds,
      });
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Error updating document:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Subject Name"
        rules={[{ required: true, message: 'Please input the name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="time"
        label="Time"
        rules={[{ required: true, message: 'Please select the time!' }]}
      >
        <TimePicker format="hh:mm A" use12Hours />
      </Form.Item>
      <Form.Item
        name="staffIds"
        label="Attendance Staff"
        rules={[{ required: true, message: 'Please select the staff!' }]}
      >
        <Select
          mode="multiple"
          placeholder="Select staff"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {staffList && staffList.length > 0 ? (
            staffList.map(staff => (
              <Select.Option key={staff.id} value={staff.id}>
                {staff.name}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled>No staff available</Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditAttendanceForm;