import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

export const fetchStudents = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.STUDENTS_COLLECTION);
    const unsubscribe = query.onSnapshot(snapshot => {
      const students = snapshot.docs.map(doc => {
        const data = doc.data();
        // Filter students with status not equal to 2 or 3
        if (data.status !== 2 && data.status !== 3) {
          return {
            id: doc.id,
            ...data
          };
        }
        return null;
      }).filter(student => student !== null); // Remove null entries
      setData(students);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching students:', error);
  }
};

export const fetchAlumni = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.STUDENTS_COLLECTION).where('status', 'in', [2, 3]);
    const unsubscribe = query.onSnapshot(snapshot => {
      const alumni = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(alumni);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching alumni:', error);
  }
};

export const fetchInstHeadStudents = async ({setData, instId}) => {
  try {
    let query;
    query = firestore.collection(collection.STUDENTS_COLLECTION).where('institution', '==', instId);
    const unsubscribe = query.onSnapshot(snapshot => {
      const students = snapshot.docs.map(doc => {
        const data = doc.data();
        // Filter students with status not equal to 2 or 3
        if (data.status !== 2 && data.status !== 3) {
          return {
            id: doc.id,
            ...data
          };
        }
        return null;
      }).filter(student => student !== null); // Remove null entries
      setData(students);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching students:', error);
  }
};

export const fetchAdminInstituions = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.INSTITUTIONS_COLLECTION).where('type','!=','non-academic');
    const unsubscribe = query.onSnapshot(snapshot => {
      const students = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(students);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchManagerInstituions = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.INSTITUTIONS_COLLECTION)
    const unsubscribe = query.onSnapshot(snapshot => {
      const students = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(students);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchAcademics = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.ACADEMIC_COLLECTION);
    const unsubscribe = query.onSnapshot(snapshot => {
      const academics = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(academics);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching acadmics:', error);
  }
};

export const fetchReligious = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.EDUCATIONAL_COLLECTION);
    const unsubscribe = query.onSnapshot(snapshot => {
      const academics = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(academics);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching religious:', error);
  }
};

export const fetchCourses = async ({setData}) => {
  try {
    let query;
    query = firestore.collection(collection.COURSES);
    const unsubscribe = query.onSnapshot(snapshot => {
      const academics = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(academics);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  } catch (error) {
    console.error('Error fetching courses:', error);
  }
};

export const levelListforChart = [ 
  { "id": 1, "name": "UP" }, 
  { "id": 2, "name": "HS" }, 
  { "id": 3, "name": "HSS" }, 
  { "id": 4, "name": "UG" }, 
  { "id": 5, "name": "PG" }, 
  { "id": 6, "name": "PhD" },
  { "id": 7, "name": "Diploma" },
  { "id": 8, "name": "B.Ed" },
  { "id": 9, "name": "M.Ed" },
  { "id": 10, "name": "LLB" },
  { "id": 11, "name": "Advaced Diploma" },
  { "id": 12, "name": "Junior Diploma" },
  { "id": 13, "name": "Senior Diploma" },
  { "id": 14, "name": "Certificate" },
]