import AdminDashBoard from '../pages/dashboard/AdminDashboard'
import Users from '../pages/users/users';
import { UserOutlined, LaptopOutlined,BankOutlined,FileOutlined , GoldOutlined} from '@ant-design/icons';
import Institutions from '../pages/institutions/institutions';
import Students from '../pages/students/Students';
import StudentDetails from '../pages/student_details/StudentDetails';
import Batches from '../pages/batches/Batches';
import ComingSoon from '../pages/extra/ComingSoon';
import InstHeadDashBoard from '../pages/dashboard/InstHeadDashBoard';
import BasicReports from '../pages/reports/BasicReports';
import ManagerDashboard from '../pages/dashboard/ManagerDashboard';
import AttendanceList from '../pages/attendance/AttendanceList';
import AttendanceDetails from '../pages/attendance/attendance_details/AttendanceDetails';
import InstitutionForAttendance from '../pages/institutions/InstitutionForAttendance';
import StaffDetails from '../pages/staff/StaffDetails';
import Staffs from '../pages/staff/Staffs';
import Alumni from '../pages/alumni/Alumni';

const managerRoutes = [
  {
    id:1,
    title:'DashBoard',
    icon: LaptopOutlined,
    path: '/dashboard',
    component: ManagerDashboard,
    show_sider:true,
  },
  {
    id:2,
    title:'Institutions',
    icon: BankOutlined,
    path: '/institutions',
    component: Institutions,
    show_sider:true,
  },
  {
    title:'Faculties',
    icon: UserOutlined,
    path: '/staffs',
    component: Staffs,
    show_sider:true,
  },
  {
    id:4,
    title:'Students',
    icon: UserOutlined,
    path: '/students',
    component: Students,
    show_sider:true,
  },
  {
    id:5,
    title:'Attendances',
    icon: GoldOutlined,
    path: '/attendance',
    component: ComingSoon,
    show_sider:true,
  },
  {
    id:6,
    title:'Reports',
    icon: FileOutlined,
    path: '/reports',
    component: BasicReports,
    show_sider:true,
  },
  {
    id:7,
    title:'Students Details',
    icon: UserOutlined,
    path: '/student_details',
    component: StudentDetails,
    show_sider:false,
  },
  {
    id:8,
    title:'Batches',
    icon: UserOutlined,
    path: '/batches',
    component: Batches,
    show_sider:false,
  },
  {
    id:9,
    title:'Staff Details',
    icon: UserOutlined,
    path: '/staff-details',
    component: StaffDetails,
    show_sider:false,
  },
 
];


const adminRoutes = [
  {
    title:'DashBoard',
    icon: LaptopOutlined,
    path: '/dashboard',
    component: AdminDashBoard,
    show_sider:true,
  },
  {
    title:'Institutions',
    icon: BankOutlined,
    path: '/institutions',
    component: Institutions,
    show_sider:true,
  },
  {
    title:'Faculties',
    icon: UserOutlined,
    path: '/staffs',
    component: Staffs,
    show_sider:true,
  },
  {
    title:'Students',
    icon: UserOutlined,
    path: '/students',
    component: Students,
    show_sider:true,
  },
  {
    title:'Alumni',
    icon: UserOutlined,
    path: '/alumni',
    component: Alumni,
    show_sider:true,
  },
  {
    title:'Attendances',
    icon: GoldOutlined,
    path: '/attendance-institutions',
    component: InstitutionForAttendance,
    show_sider:true,
  },
  {
    title:'Attendances',
    icon: GoldOutlined,
    path: '/attendance',
    component: AttendanceList,
    show_sider:false,
  },
  {
    title:'Reports',
    icon: FileOutlined,
    path: '/reports',
    component: BasicReports,
    show_sider:true,
  },
  {
    title:'Students Details',
    icon: UserOutlined,
    path: '/student_details',
    component: StudentDetails,
    show_sider:false,
  },
  {
    title:'Batches',
    icon: UserOutlined,
    path: '/batches',
    component: Batches,
    show_sider:false,
  },
  {
    title:'Attendance Details',
    icon: UserOutlined,
    path: '/attendance_details',
    component: AttendanceDetails,
    show_sider:false,
  },
  {
    title:'Staff Details',
    icon: UserOutlined,
    path: '/staff-details',
    component: StaffDetails,
    show_sider:false,
  },
 
];
const studentRoutes=[
  // {
  //   title:'DashBoard',
  //   icon: LaptopOutlined,
  //   path: '/dashboard',
  //   component: DashBoard,
  //   show_sider:true,
  // },
  {
    title:'My Profile',
    icon: UserOutlined,
    path: '/student_details',
    component: StudentDetails,
    show_sider:true,
  },
]
const institutionHeadRoutes=[
  
  {
    id:1,
    title:'DashBoard',
    icon: LaptopOutlined,
    path: '/dashboard',
    component: InstHeadDashBoard,
    show_sider:true,
  },
  {
    id:2,
    title:'Batches',
    icon: UserOutlined,
    path: '/batches',
    component: Batches,
    show_sider:true,
  },
  {
    id:2,
    title:'Faculties',
    icon: UserOutlined,
    path: '/staffs',
    component: Staffs,
    show_sider:true,
  },
  {
    id:4,
    title:'Students',
    icon: UserOutlined,
    path: '/students',
    component: Students,
    show_sider:true,
  },
  {
    id:10,
    title:'Alumni',
    icon: UserOutlined,
    path: '/alumni',
    component: Alumni,
    show_sider:true,
  },
  {
    id:5,
    title:'Attendances',
    icon: GoldOutlined,
    path: '/attendance',
    component: AttendanceList,
    show_sider:true,
  },
  {
    id:6,
    title:'Attendance Details',
    icon: UserOutlined,
    path: '/attendance_details',
    component: AttendanceDetails,
    show_sider:false,
  },
  {
    id:7,
    title:'Reports',
    icon: FileOutlined,
    path: '/reports',
    component: BasicReports,
    show_sider:true,
  },
  {
    id:8,
    title:'Students Details',
    icon: UserOutlined,
    path: '/student_details',
    component: StudentDetails,
    show_sider:false,
  },
  {
    id:9,
    title:'Staff Details',
    icon: UserOutlined,
    path: '/staff-details',
    component: StaffDetails,
    show_sider:false,
  },
]
const staffRoutes=[

  {
    title:'My Profile',
    icon: UserOutlined,
    path: '/staff-details',
    component: StaffDetails,
    show_sider:true,
  },
]

export {managerRoutes,adminRoutes,studentRoutes,institutionHeadRoutes,staffRoutes};