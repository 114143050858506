import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

export const fetchStaffs = async ({ filter = {} }) => {
  try {
    let query = firestore.collection(collection.STAFF_COLLECTION);

    // Apply filter if provided
    if (filter.institution) {
      query = query.where('institution', '==', filter.institution);
    }

    const snapshot = await query.get();
    const staffs = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return staffs;
  } catch (error) {
    console.error('Error fetching staffs:', error);
    return [];
  }
};

export const fetchAttendanceData = (callback) => {
  return firestore.collection(collection.ATTENDANCE_LIST_COLLECTION)
    .onSnapshot((snapshot) => {
      const updatedData = snapshot.docs.map((doc, index) => ({
        key: doc.id,
        sl_no: index + 1,
        ...doc.data(),
      }));
      callback(updatedData);
    }, (error) => {
      console.error('Error fetching data:', error);
    });
};

export const addAttendanceRecord = async (record) => {
  try {
    await firestore.collection(collection.ATTENDANCE_LIST_COLLECTION).add(record);
  } catch (error) {
    console.error('Error adding document:', error);
    throw error;
  }
};

export const updateAttendanceRecord = async (key, record) => {
  try {
    await firestore.collection(collection.ATTENDANCE_LIST_COLLECTION).doc(key).update(record);
  } catch (error) {
    console.error('Error updating document:', error);
    throw error;
  }
};

export const deleteAttendanceRecord = async (key) => {
  try {
    await firestore.collection(collection.ATTENDANCE_LIST_COLLECTION).doc(key).delete();
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};