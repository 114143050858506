import React, { useEffect, useState } from 'react';
import { useAllStudents } from '../../context/allStudentContext';
import { useInstitution } from '../../context/InstitutionContext';
import { Row, Col, Card, Statistic, List, Progress } from 'antd';
import { useUserData } from '../../context/userDataContext';
import { fetchAcademics, fetchAdminInstituions, fetchAlumni, fetchStudents } from './store';
import { useAllAcademic } from '../../context/allAcademicContext';
import { useAllAlumni } from '../../context/allAlumniContext';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

function AdminDashBoard() {
  const { allStudents, setAllStudents } = useAllStudents();
  const { allAlumni, setAllAlumni } = useAllAlumni();
  const { institutionList, setInstitutionList } = useInstitution();
  const { allAcademic, setAllAcademic } = useAllAcademic();
  const { userData } = useUserData();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (userData.role) {
      fetchStudents({ setData: setAllStudents });
      fetchAdminInstituions({ setData: setInstitutionList });
      fetchAcademics({ setData: setAllAcademic });
      fetchAlumni({ setData: setAllAlumni });
    }
  }, [userData, setAllStudents, setInstitutionList, setAllAcademic, setAllAlumni]);

  useEffect(() => {
    const unsubscribe = firestore.collection(collection.NOTIFICATIONS_COLLECTION)
      .orderBy('timestamp', 'desc')
      .limit(10)
      .onSnapshot(snapshot => {
        const fetchedNotifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotifications(fetchedNotifications);
      });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const verifiedStudents = allStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined);
  const imageUpdated = allStudents.filter((e) => e.imagePath !== undefined);

  const imageUpdatedPercentage = allStudents.length > 0
    ? ((imageUpdated.length / allStudents.length) * 100).toFixed(1)
    : 0;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Institutions"
              value={institutionList.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Verified Students"
              value={verifiedStudents.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Alumni"
              value={allAlumni.length}
              valueStyle={{ color: '#f07c07' }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col xs={24} sm={16} md={16}>
          <Card title="Number of Students">
            <List
              itemLayout="horizontal"
              dataSource={institutionList.map(institution => ({
                ...institution,
                totalStudents: allStudents.filter(student => student.institution === institution.id).length
              }))}
              renderItem={institution => (
                <List.Item>
                  <List.Item.Meta
                    title={<span>{institution.name}</span>}
                    description={`Total Students: ${institution.totalStudents}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        {/* <Col xs={24} sm={8} md={8}>
          <Card title="Image Updated" style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Progress
                type="circle"
                percent={imageUpdatedPercentage}
                success={{
                  percent: imageUpdatedPercentage,
                }}
              />
            </div>
          </Card>
        </Col> */}
        <Col xs={24} sm={8} md={8}>
          <Card title="Recent Notifications">
            <List
              itemLayout="horizontal"
              dataSource={notifications}
              renderItem={notification => (
                <List.Item>
                  <List.Item.Meta
                    title={notification.message}
                    description={new Date(notification.timestamp.seconds * 1000).toLocaleString()}
                  />
                </List.Item>
              )}
              style={{ maxHeight: '200px', overflowY: 'auto' }} // Set fixed height and enable scrolling
            />
          </Card>
        </Col>
      </Row>

      
    </>
  );
}

export default AdminDashBoard;
